import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateEmail } from '@/Framework/UI/Organisms/FinalForm/validators/user/validateEmail';
import { validateRequiredFields } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredFields';

const fieldsList = [
  { fieldName: 'email', fieldCaption: 'Email' },
  { fieldName: 'password', fieldCaption: 'Password' },
];

export default (values) => groupValidators(
  validateRequiredFields(
    fieldsList,
    values,
  ),
  // @ts-ignore
  validateEmail({ fieldName: 'email', fieldCaption: 'Email', value: values.email }),
);
