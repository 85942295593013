import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { RetainQueryLink } from '@/Framework/Router/Next/Link';
import Head from 'next/head';
import LoginContext, { useLoginContext } from '@/users/application/Session/LoginContext';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { useTenantThemeContext } from '@/users/application/TenantThemeContext';

import Button from '@/Framework/UI/Atoms/Button';
import UsersContent from '@/users/ui/components/UsersContent';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import EmailInput from '@/Framework/UI/Organisms/FinalForm/Fields/EmailInput';
import PasswordInput from '@/Framework/UI/Organisms/FinalForm/Fields/PasswordInput';
import TermsOfUse from '@/Framework/UI/Atoms/TermsOfUse';
import NeedAnAccount from './NeedAnAccount';
import validate from './validator';
import useSSOValidation from '@/users/ui/helpers/useSSOValidation';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';

import contentStyles from '@/users/ui/components/UsersContent/usersContent.scss';
import styles from './loginStyles.scss';
import themeStyles from '@/users/ui/common/theme.scss';

const LOGIN_FORM_NAME = 'loginForm';

const Login = () => {
  const {
    logo,
    logoTitle,
    copyright,
    buttonVariant,
    loginPage: {
      title,
      subtitle,
      submitButtonText,
    },
  } = useTenantThemeContext();
  const { session, currentUser, initialized } = useSessionContext();
  const { login, transferring, transferExistingSession } = useLoginContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const checkIsSSODomain = useSSOValidation();

  const handleSubmit = (values: object) => {
   setIsSubmitting(true);

   return login(values).catch(() => {
     setIsSubmitting(false);
   });
  };

  useEffect(() => { // Lets try to transfer existing session instead of asking for creds again
   if (
     initialized &&
     session &&
     currentUser
   ) {
      transferExistingSession();
    }
  }, [
    initialized,
    session?.ssid,
    currentUser?.id,
  ]);

  const renderFields: TFinalFormRenderProp = ({ invalid }, { Field }) => (
    <>
      <Field
        component={ EmailInput }
        name="email"
        dataTest="email"
        placeholder="Corporate Email"
        inputClassName={ styles.formInput }
        validate={ checkIsSSODomain }
        isLastPassAutofillEnabled
      />
      <Field
        component={ PasswordInput }
        dataTest="password"
        name="password"
        inputClassName={ styles.formInput }
        isLastPassAutofillEnabled
      />
      <Button
        variant={ buttonVariant }
        type="submit"
        disabled={ invalid || isSubmitting }
        title={ submitButtonText }
        block
        dataTest="logInWithFinsightButton"
        className={ themeStyles.button }
      />
      <TermsOfUse className={ themeStyles.secondaryText } />
      <RetainQueryLink
        className={ cn(contentStyles.bottomLink, themeStyles.link) }
        href="/forgot_password"
      >
        Forgot your password?
      </RetainQueryLink>
    </>
  );

  return initialized && !session && !transferring && (
    <>
      <Head>
        <title>Finsight | Login</title>
      </Head>
      <UsersContent
        title={ title }
        subtitle={ subtitle }
        logo={ logo }
        logoTitle={ logoTitle }
        copyright={ copyright }
        footerContent={ <NeedAnAccount className={ themeStyles.secondaryText } /> }
        showPreloader={ isSubmitting }
        contentWrpClassName={ themeStyles.tile }
        titleClassName={ themeStyles.title }
        subTitleClassName={ themeStyles.secondaryText }
      >
        <FinalForm
          name={ LOGIN_FORM_NAME }
          dataTest={ LOGIN_FORM_NAME }
          onSubmit={ handleSubmit }
          render={ renderFields }
          validate={ validate }
        />
      </UsersContent>
    </>
  );
};

export default (props) => (
  <LoginContext>
    <Login { ...props } />
  </LoginContext>
);
